import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { get, isEmpty, size } from 'lodash';

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  checkBox: {
    paddingTop: '20px'
  }
}));

const TimeZoneFilterComponent = (props) => {

  const { filter, onFilterChange } = props;

  const classes = useStyles();

  let defaultValue = {
    multiple: isEmpty(get(filter, 'value', '')) && get(filter, 'isMultiple', false) ? [] : get(filter, 'value'),
    simple: isEmpty(get(filter, 'value', '')) && !get(filter, 'isMultiple', false) ? '' : get(filter, 'value'),
  };

  const [state, setState] = useState({
    value: get(filter, 'isMultiple', false) ? get(defaultValue, 'multiple') : get(defaultValue, 'simple'),
    selectAll: get(filter, 'isMultiple', false) ? size(get(filter, 'value')) > 1 : false
  });

  const onLocalChange = (value, selectAll) => {
    onFilterChange(filter, value);
    setState({ value, selectAll });
  };

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <FormControl disabled={get(filter, 'disabled', false)} className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select Timezone</InputLabel>
        <Select
          id="demo-mutiple-name"
          labelId="demo-simple-select-label"
          value={get(state, 'value')}
          onChange={e => onLocalChange(e.target.value)}>
          {get(filter, 'data', []).map(item => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
  )
};

export default TimeZoneFilterComponent;