
import React, {useState , useContext} from "react";
import { SettingsContext } from "../../../contexts/SettingsContext";
import {
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {get} from "lodash";


const FilterByProject = props => {

console.log("Filter By Project ")

    const {onFilterChange, filter} = props;

    const settingsContext = useContext(SettingsContext);

    const onChangeShowProject = (value) => {
        settingsContext.onEnableFilter('projectFilterComponent' ,!value)

        onFilterChange(filter , value)

    }

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={get(filter, 'value', false)}
                        onChange={(e) => onChangeShowProject(e.target.checked)}
                        color="primary"
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />
                }
                label="Filter by Project"
            />
        </div>
    )
};

export default FilterByProject;