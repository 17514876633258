import React, { useContext } from 'react'
import { SettingsContext } from '../../contexts/SettingsContext';
import { get } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';


export default function AppNotification(props) {

  const settingsContext = useContext(SettingsContext);

  const onDownload = (document) => {
    if (get(document, 'documentStatus') === 'ready') {
      settingsContext.onShowDocuments();
      settingsContext.onDownloadDocument(document, true)
    }
  }

  return (
    <div style={{ minWidth: '360px' }} className="messages-list">
      <ul className="list-unstyled" style={{ maxHeight: '400px', overflow: 'scroll' }}>
        {get(settingsContext, 'export.documents').map((document, index) => (
          <li key={index} className="media d-flex align-items-center ">
            <i
              style={{ fontSize: '25px', cursor: `${get(document, 'documentStatus') === 'ready' ? 'pointer' : 'auto'}` }}
              className={`zmdi zmdi-download mr-4 ml-1 ${get(document, 'documentStatus') === 'ready' ? '' : 'option-disabled'}`}
              onClick={() => onDownload(document)}
            />
            <div className="media-body align-self-center">
              <p className="ml-2 mb-0">{get(document, 'documentName')}</p>
              <span className="ml-2 meta-date">
                <small>From: {get(document, 'dateRange._from')}</small>
              </span>
              <span className="ml-2 meta-date">
                <small>To: {get(document, 'dateRange._to')}</small>
              </span>
              <span className="ml-2 meta-date">
                <small>Type: {get(document, 'exportType')}</small>
              </span>
            </div>
            {get(document, 'documentStatus', 'processing') === 'ready' ?
              <i style={{ color: '#8dbf44', fontSize: '25px' }} className="zmdi zmdi-cloud-done"></i>
              : <CircularProgress size={20} />
            }
          </li>
        ))}
      </ul>
    </div>

  )
};