import React, { useState, useContext } from "react";
import '../../../static/styles/components/_dateFilterComponent.scss';
import { SettingsContext } from '../../../contexts/SettingsContext'
import { get, cloneDeep, isNull } from 'lodash';
import moment from "moment";
import { DatePicker, Form } from 'antd';
const { RangePicker } = DatePicker;


const DateRangeFilterComponent = (props) => {

    const { filter, onFilterChange } = props;

    const settingsContext = useContext(SettingsContext);

    const [dateValue, setDateValue] = useState(
        isNull(get(filter, 'value._from', null)) ? null :
            [moment(get(filter, 'value._to')), moment(get(filter, 'value._from'))]
    );

    const onLocalChange = (value) => {


        const _from = cloneDeep(value[0]);
        const _to = cloneDeep(value[1]);

        if (_from.isAfter() || _to.isAfter()) settingsContext.onNotificationError('error', "Can't select dates in the future!")
        else {
            setDateValue(value);
            onFilterChange(filter, { _from: _from.format('LL'), _to: _to.format('LL') })
        }



    };
    return <Form.Item label="Date Range">

        <RangePicker
            format="MM/DD/YYYY"
            value={dateValue}
            onChange={(event) => onLocalChange(event)}
        />
    </Form.Item>

};


export default DateRangeFilterComponent;