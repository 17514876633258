import React, { useState } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import '../../static/styles/components/_downloadDocumentComponent.scss';

import {
  DownloadNowComponent,
  SendByEmailComponent
} from '../index';

function TabContainer(props) {
  return (
    <div style={{ padding: 20 }}>
      {props.children}
    </div>
  );
}

export default function DownloadDocumentContent({ elem }) {

  const [value, setValue] = useState(0)

  const onHandleChange = (event, value) => setValue(value);


  return (
    <div>
      <AppBar className="primary" position="static">
        <Tabs value={value} onChange={onHandleChange} variant="scrollable" scrollButtons="on">
          <Tab className="tab" label="Send By Email" />
          <Tab className="tab" label="Download now" />
        </Tabs>
      </AppBar>
      {value === 0 &&
        <TabContainer>
          <SendByEmailComponent elem={elem} />
        </TabContainer>}
      {value === 1 &&
        <TabContainer>
          <DownloadNowComponent elem={elem} />
        </TabContainer>}
    </div>
  )
};