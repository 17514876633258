import { NotificationManager } from 'react-notifications';
import { get, isEmpty, isNull, isUndefined } from "lodash";
import { getUserToken } from "./userTokenService";
import axios from 'axios';
import { errorService } from "./errorService";

const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'http://localhost:3000/v1');


const setHeaders = () => {
    return {
        'Authorization': `Bearer ${getUserToken()}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*"
    };
};

export const userProfile = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${baseUrl}/user-profile`, { headers: setHeaders() }).then(res => {
            resolve(res);
        }).catch(err => {
            errorService(err);
        });
    });
};

export const leftMenuOptions = () => {
    return [
        {
            idx: 0,
            key: 'construction',
            title: 'Construction',
            icon: 'zmdi zmdi-balance'
        },
        {
            idx: 1,
            key: 'payroll',
            title: 'Payroll',
            icon: 'zmdi zmdi-balance'
        }
    ];
};

export const notificationCenter = (type, message) => {
    switch (type) {
        case 'info':
            NotificationManager.info(message);
            break;
        case 'success':
            NotificationManager.success(message);
            break;
        case 'warning':
            NotificationManager.warning(message);
            break;
        case 'error':
            NotificationManager.error(message);
            break;
    }
};

export const notValid = value => {
    return isUndefined(value) || isEmpty(value) || isNull(value);
};


