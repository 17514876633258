import React, { useContext, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { get, isEmpty, size } from 'lodash';

import makeStyles from "@material-ui/core/styles/makeStyles";
import { SettingsContext } from "../../../contexts/SettingsContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  checkBox: {
    paddingTop: '20px'
  }
}));

const CompanyCrewsComponent = (props) => {

  const settingsContext = useContext(SettingsContext);


  const { filter, onFilterChange } = props;

  const classes = useStyles();

  let defaultValue = {
    multiple: isEmpty(get(filter, 'value', '')) && get(filter, 'isMultiple', false) ? [] : get(filter, 'value'),
    simple: isEmpty(get(filter, 'value', '')) && !get(filter, 'isMultiple', false) ? '' : get(filter, 'value'),
  };

  const [state, setState] = useState({
    value: get(filter, 'isMultiple', false) ? get(defaultValue, 'multiple') : get(defaultValue, 'simple'),
    selectAll: get(filter, 'isMultiple', false) ? size(get(filter, 'value')) > 1 : false
  });

  const onLocalChange = (value, selectAll) => {

    settingsContext.onChangeCrewCompanyFilter('crew', true);
    onFilterChange(filter, value);
    setState({ value, selectAll });
  };

  const onAllChange = (e) => {

    if (!e) {
      onLocalChange([], false);
      return;
    }

    const ids = get(filter, 'data').map(item => get(item, '_id'));
    onLocalChange(ids, true);
  };

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <FormControl disabled={get(filter, 'disabled', false) || state.selectAll} className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Select Crew</InputLabel>
        <Select
          id="demo-mutiple-name"
          disabled={get(settingsContext.crewCompanyFilter, 'type', 'crew') === 'company' ? true : false}
          multiple={get(filter, 'isMultiple', false)}
          labelId="demo-simple-select-label"
          value={get(state, 'value')}
          onChange={e => onLocalChange(e.target.value)}>
          {get(filter, 'data', []).map(item => (
            <MenuItem key={get(item, '_id')} value={get(item, '_id')}>{get(item, 'name')}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {get(filter, 'isMultiple', false) &&
        <FormControlLabel
          className={classes.checkBox}
          disabled={get(filter, 'disabled', false)}
          control={
            <Checkbox
              checked={state.selectAll}
              onChange={(e) => onAllChange(e.target.checked)}
              name="checkedB"
              color="primary"
            />
          }
          label="All"
        />}

    </div>
  )
};

export default CompanyCrewsComponent;