import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Paper} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const Footer = () => {

    const classes = useStyles();

    return (

        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.paper}>Copyright &copy; {(new Date().getFullYear())} SmartBarrel. All rights reserved.</Paper>
            </Grid>
        </Grid>

    );
};

export default Footer;