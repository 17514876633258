import React, { useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide
} from '@material-ui/core';

import { Input } from 'antd';

import { notificationCenter } from '../../services/appService'

import { makeStyles } from "@material-ui/core/styles";
import {
    FileCopy,
    OpenInBrowser
} from '@material-ui/icons';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
    paper: {
        width: "100% !important"
    },
    button: {
        marginLeft: '4px',
        backgroundColor: '#8b8f9c',
        color: 'white',
        fontWeight: 'bold'
    },
}));


const ExportDialogComponent = (props) => {

    const classes = useStyles();

    const inputLinkRef = useRef(null);

    const onCopyLink = () => {
        inputLinkRef.current.select();
        console.log("ASDASDASDAS")
        // document.execCommand('copy');
        notificationCenter('success', 'Link Copied!')
    };

    const onOpenLink = () => {
        window.open(props.shareLink, '_blank');
    };

    return (
        <Dialog
            open={props.open}
            className={classes.paper}
            TransitionComponent={Transition}
            disableBackdropClick={true}
            keepMounted
            maxWidth={props.maxWidth}
            onClose={() => props.onExportDialogClose()}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                <i className="zmdi zmdi-share pr-2" />

                Link to Share
            </DialogTitle>
            <DialogContent>
                <div className="input-share-link d-flex justify-content-between align-items-center">
                    <Input ref={inputLinkRef} readOnly value={props.shareLink} />
                    <CopyToClipboard text={props.shareLink}
                        onCopy={() => onCopyLink()}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<FileCopy />}
                        >
                            Copy
                    </Button>
                    </CopyToClipboard>

                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => onOpenLink()}
                        startIcon={<OpenInBrowser />}
                    >
                        Open
                    </Button>
                </div>
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={() => props.onExportDialogClose()}>
                    Done
                </Button>
            </DialogActions>

        </Dialog>

    );
};


export default ExportDialogComponent;