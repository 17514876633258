import React, {useState} from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel
} from '@material-ui/core';
import {get, isEmpty} from 'lodash';

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 300
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    checkBox: {
        paddingTop: '20px'
    }
}));

const CompanyFilterComponent = (props) => {

    const {filter, onFilterChange} = props;

    const classes = useStyles();

    let defaultValue = {
        multiple: isEmpty(get(filter, 'value', '' )) && get(filter, 'isMultiple', false) ? [] : get(filter, 'value'),
        simple:  isEmpty(get(filter, 'value', '' )) && !get(filter, 'isMultiple', false) ? '[]' : get(filter, 'value'),
    };

    const [state, setState] = useState({
        value: get(filter, 'isMultiple', false) ? get(defaultValue , 'multiple') : get(defaultValue, 'simple'),
        selectAll: false
    });

    const onLocalChange = (value) => {
        onFilterChange(filter, value);

        setState({...state, value});
    };

    const onAllChange = (e) => {
        const val = e.target.checked;
        const ids = get(filter, 'data').map(item => get(item, '_id'));
        setState({value: ids, selectAll: val});
        onFilterChange(filter, ids);
    };

    return (
        <div className='d-flex justify-content-center align-items-center'>
            <FormControl disabled={get(filter, 'disabled', false) || state.selectAll} className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Select CostCode</InputLabel>
                <Select
                    id="demo-mutiple-name"
                    multiple={get(filter, 'isMultiple', false)}
                    labelId="demo-simple-select-label"
                    value={get(state, 'value')}
                    onChange={e => onLocalChange(e.target.value)}>
                    {get(filter, 'data', []).map(item => (
                        <MenuItem key={get(item, '_id')} value={get(item, '_id')}>{get(item, 'name')}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {get(filter, 'isMultiple', false) &&
            <FormControlLabel
                className={classes.checkBox}
                disabled={get(filter, 'disabled', false)}
                control={
                    <Checkbox
                        checked={state.selectAll}
                        onChange={(e) => onAllChange(e)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="All"
            />}

        </div>
    )
};

export default CompanyFilterComponent;