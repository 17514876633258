import React from "react";
import { Widget } from "../../components";
import { filter, get } from 'lodash';

import {
    DateFilterComponent,
    DateRangeFilterComponent,
    ProjectFilterComponent,
    CompanyFilterComponent,
    ShowCostCodeFilterComponent,
    WorkerFilterComponent,
    CostcodeFilterComponent,
    HourlyTypeFilterComponent,
    ShiftApprovedFilterComponent,
    OtEmployeeFilterComponent,
    ShowDetailsComponent,
    SubsCompanyFilterComponent,
    ShowPhoneNumberFilter,
    ShowPerDiemBreakout,
    ShowLunchBreakFilter,
    ShowPerdiemElegibilityFilter,
    TimeZoneFilterComponent,
    WeeklyDateRangeFilter,
    CompanyCrewsComponent,
    ShowWorkSummary,
    FilterByCostCode,
    FilterByProject

} from './FiltersHelperComponents/index';

import '../../static/styles/components/_filtersComponents.scss';

const FiltersComponents = (props) => {

    const { filters, onFilterChange, onFilterDependencies } = props;

    console.log("filters ", filters)

    return (
        <Widget styleName='mt-2 filters-components'>
            {filters.map(filter => (
                <div key={get(filter, 'key')} className='filter-item ml-4'>
                    {get(filter, 'key') === 'dateFilter' ?
                        <DateFilterComponent onFilterChange={onFilterChange} filter={filter} /> : null}
                    {get(filter, 'key') === 'dateRangeFilter' ?
                        <DateRangeFilterComponent onFilterChange={onFilterChange} filter={filter} /> : null}
                    {get(filter, 'key') === 'projectFilter' ?
                        <ProjectFilterComponent
                            onFilterChange={onFilterChange}
                            onFilterDependencies={onFilterDependencies}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'companyFilter' ?
                        <CompanyFilterComponent
                            onFilterChange={onFilterChange}
                            onFilterDependencies={onFilterDependencies}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'subsCompanyFilter' ?
                        <SubsCompanyFilterComponent
                            onFilterChange={onFilterChange}
                            onFilterDependencies={onFilterDependencies}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'workerFilter' ?
                        <WorkerFilterComponent
                            onFilterChange={onFilterChange}
                            onFilterDependencies={onFilterDependencies}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'costCodeFilter' ?
                        <CostcodeFilterComponent
                            onFilterChange={onFilterChange}
                            onFilterDependencies={onFilterDependencies}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showCostCodeFilter' ?
                        <ShowCostCodeFilterComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'shiftApprovedFilter' ?
                        <ShiftApprovedFilterComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}

                    {get(filter, 'key') === 'showWorkSummary' ?
                        <ShowWorkSummary
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'otEmployeeFilter' ?
                        <OtEmployeeFilterComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showDetailsFilter' ?
                        <ShowDetailsComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showPhoneNumberFilter' ?
                        <ShowPhoneNumberFilter
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showPerDiemBreakout' ?
                        <ShowPerDiemBreakout
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showLunchBreakFilter' ?
                        <ShowLunchBreakFilter
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'showPerdiemElegibilityFilter' ?
                        <ShowPerdiemElegibilityFilter
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'timezone' ?
                        <TimeZoneFilterComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'weeklyDateRange' ?
                        <WeeklyDateRangeFilter
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'companyCrews' ?
                        <CompanyCrewsComponent
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'filterByCostCode' ?
                        <FilterByCostCode
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                    {get(filter, 'key') === 'filterByProject' ?
                        <FilterByProject
                            onFilterChange={onFilterChange}
                            filter={filter} /> : null}
                </div>
            ))}
        </Widget>
    )
};

export default FiltersComponents;