
import React, {useState } from "react";

import {
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {get} from "lodash";


const FilterByCostCode = props => {

    const {onFilterChange, filter} = props;

    const onChangeShowCostCode = (value) => {
        onFilterChange(filter , value)
    }

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={get(filter, 'value', false)}
                        onChange={(e) => onChangeShowCostCode(e.target.checked)}
                        color="primary"
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />
                }
                label="Filter by CostCode"
            />
        </div>
    )
};

export default FilterByCostCode;