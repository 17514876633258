import React, { useState, createContext } from 'react';
import { notificationCenter } from '../services/appService';
import { get, has, isUndefined, uniqBy } from 'lodash';
import { NotificationManager } from 'react-notifications';


export const SettingsContext = createContext();

export default function SettingsContextProvider(props) {

  const [state, setState] = useState({
    loader: {
      status: false,
      msg: 'loading'
    },
    error: {
      status: '',
      msg: ''
    },
    notifications: [],
    downloadDocument: {
      open: false,
      document: null,
    },
    loadDocuments: false,
    export: {
      open: false,
      documents: []
    },
    crewCompanyFilter: {
      type: '',
      status: false
    },
    enableFilter: {
      key: '',
      value: false
    }
  })

  const onChangeLoader = (status, msg = 'loading') => {
    setState(prevState => ({
      ...prevState,
      loader: {
        status,
        msg
      }
    }))
  }

  const onChangeCrewCompanyFilter = (type, status) => {
    console.log(type, status)
    setState(prevState => ({
      ...prevState,
      crewCompanyFilter: {
        type,
        status
      }
    }))
  }

  const onDownloadDocument = (document = null, open = false) => {
    setState(prevState => ({
      ...prevState,
      loadDocuments: false,
      downloadDocument: {
        open: open,
        document: document
      }
    }))
  }

  const onAddDocuments = (document, initialState = false) => {

    setState(prevState => ({
      ...prevState,
      export: {
        ...prevState.export,
        loadDocuments: true,
        documents: initialState ? document : [document, ...prevState.export.documents.filter(item => get(item, 'exportAuxId') !== get(document, 'exportAuxId'))],
      }
    }))
  }

  const onShowDocuments = () => {
    setState(prevState => ({
      ...prevState,
      export: {
        ...prevState.export,
        open: !prevState.export.open
      }
    }))
  }

  const onEnableFilter = (key , value) => {
    setState(prevState => ({
      ...prevState,
      enableFilter: {
        key,
        value
      }
    }))
  }


  const onRequestErrorMessage = (response) => {

    console.log(response);

    if (get(response, 'status') === 302 || isUndefined(response)) {
      window.location.href = get(response, 'data.location', `${get(process.env, 'REACT_APP_BASE_DASHBOARD_URL')}/signoff`)
    } else {
      onChangeLoader(false)
      NotificationManager.error(`${get(response, 'status', 500)} - ${get(response, 'data.error', 'Contact Us!')}`);
    }
  }

  const onNotificationError = (key, message = '') => {
    switch (key) {
      case 'info':
        NotificationManager.info(message);
        break;
      case 'success':
        NotificationManager.success(message);
        break;
      case 'warning':
        NotificationManager.warning(message);
        break;
      case 'error':
        NotificationManager.error(message);
        break;
    }
  }


  return (
    <SettingsContext.Provider
      value={{
        ...state,
        pageSize: 50,
        onChangeLoader,
        onRequestErrorMessage,
        onNotificationError,
        onShowDocuments,
        onDownloadDocument,
        onAddDocuments,
        onChangeCrewCompanyFilter,
        onEnableFilter
      }}>
      {props.children}
    </SettingsContext.Provider>
  )
};
