import React from 'react';
import PropTypes from 'prop-types';
import '../../static/styles/components/_weatherWidget.scss';
import * as _ from 'lodash';

const iconUrl = 'https://static-files.connectedbarrel.com/icons/weather-widget';

let hourWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <th key={idx}>{_.get(item, 'time')}</th>
        ))
    );
};

let iconWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <td key={idx}><img src={`${iconUrl}/${_.get(item, 'weatherIcon')}.svg`} alt="Weather Icon" /></td>
        ))
    );
};

let phraseWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <td key={idx}>{_.get(item, 'iconPhrase')}</td>
        ))
    );
};

let tempWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <td key={idx}>{`${_.get(item.weather, 'temperature.Value')} ${_.get(item.weather, 'temperature.Unit')}`}</td>
        ))
    );
};

let windWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <td key={idx}>
                <div className="sm__table-compass-wrapper">
                    <div className={`sm__table-compass__arrow--wrapper ${_.lowerCase(_.get(item.weather, 'wind.Direction.English'))}`}>
                        <div className="sm__table-compass__arrow">
                            <i className="fas fa-caret-down"></i>
                        </div>
                    </div>
                    <div className="sm__table-compass__text">
                        <span className="sm__table-compass__text--direction">
                            {_.get(item.weather, 'wind.Direction.English')}
                        </span>
                        <span className="sm__table-compass__text--speed">{_.get(item.weather, 'wind.Speed.Value')}</span>
                    </div>
                </div>
            </td>
        ))
    );
};

let rainSnowWeatherSync = weatherData => {

    let snowData = (snow, idx) => {
        return (<td key={idx}><i className='fas fa-snowflake-o'></i>{` ${_.get(snow, 'Value')} ${_.get(snow, 'Unit')}`}</td>)
    };
    let rainData = (rain, idx) => {
        return (<td key={idx}><i className='fas fa-tint'></i>{` ${_.get(rain, 'Value')} ${_.get(rain, 'Unit')}`}</td>)
    };

    return (
        weatherData.map((item, idx) => {
            let snow = _.get(item.weather, 'snow');
            let rain = _.get(item.weather, 'rain');

            if (_.get(rain, 'Value') === 0 && !_.isNull(snow))
                return snowData(snow, idx);
            else
                return rainData(rain, idx);
        })
    );
};


let relativeHumidityWeatherSync = weatherData => {
    return (
        weatherData.map((item, idx) => (
            <td key={idx}>{`${_.get(item.weather, 'relativeHumidity')}%`}</td>
        ))
    );
};



const WeatherWidget = ({ weatherData }) => {
    return (

        <div className="weather-widget-table">
            <table cellPadding="0" border="0" cellSpacing="0" className="sm__report-table" width="100%">
                <tbody>
                    <tr className="sm__report-table--headers" >
                        {hourWeatherSync(weatherData)}
                    </tr >
                    <tr className="sm__report-table--row">
                        {iconWeatherSync(weatherData)}
                    </tr>
                    <tr className="sm__report-table--row">
                        {phraseWeatherSync(weatherData)}
                    </tr>
                    <tr className="sm__report-table--row">
                        {tempWeatherSync(weatherData)}
                    </tr>
                    <tr className="sm__report-table--row">
                        {windWeatherSync(weatherData)}
                    </tr>
                    <tr className="sm__report-table--row">
                        {rainSnowWeatherSync(weatherData)}
                    </tr>
                    <tr className="sm__report-table--row">
                        {relativeHumidityWeatherSync(weatherData)}
                    </tr>
                </tbody>

            </table >
        </div>

    );
};

WeatherWidget.propTypes = {
    weatherData: PropTypes.array
};

WeatherWidget.defaultProps = {
    weatherData: []
};

export default WeatherWidget;