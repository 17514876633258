import axios from 'axios';
import { get } from 'lodash';
const baseUrl = get(process.env, 'REACT_APP_BASE_URL', 'http://localhost:3000');

const setHeaders = () => {

    return {
        'Authorization': `Bearer ${localStorage.getItem('userToken')}`,
        'Accept': 'application/json',
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': "*"
    };
};

export const getReports = () => {
    return axios.get(`${baseUrl}/reports`, { headers: setHeaders() });
};

export const getUserConfig = () => {
    return axios.get(`${baseUrl}/user-config`, { headers: setHeaders() });
};

export const getReportConfig = (reportKey) => {
    return axios.get(`${baseUrl}/report-config/${reportKey}`, { headers: setHeaders() });
};

export const getDocuments = (reportKey, pagination) => {

    return axios.post(`${baseUrl}/documents`, {
        reportKey,
        pagination
    }, { headers: setHeaders() });
};

export const getCompaniesByProject = (projectId) => {
    return axios.get(`${baseUrl}/companies-project/${projectId}`, { headers: setHeaders() });
};

export const populatingFiltersDependencies = (body, filterKey) => {
    return axios.post(`${baseUrl}/populate-filters/${filterKey}`, body, { headers: setHeaders() });
};

export const getWorkersByCompany = (companyId) => {
    return axios.get(`${baseUrl}/workers-company/${companyId}`, { headers: setHeaders() });
};

export const getCostcodesByProject = (projectId) => {
    return axios.get(`${baseUrl}/costcodes-project/${projectId}`, { headers: setHeaders() });
};

export const getReportData = (data) => {
    return axios.post(`${baseUrl}/report-data`, data, { headers: setHeaders() });
};

export const exportReportData = (data) => {
    return axios.post(`${baseUrl}/export-data`, data, { headers: setHeaders() });
};

export const getWorkerOnboardingForm = form => {
    return axios.get(`${get(process.env, 'REACT_APP_ONBOARDING_FORM_URL')}${get(form, 'dbid')}/url`, { headers: setHeaders() });
};

export const getLastDownloadDocuments = () => {
    return axios.get(`${baseUrl}/documents-downloaded`, { headers: setHeaders() });
}

export const emailsSearch = () => {
    return axios.get(`${baseUrl}/email-search`, { headers: setHeaders() });
}

export const sendEmails = (payload) => {
    return axios.post(`${baseUrl}/send-documents`, payload, { headers: setHeaders() });
}