import React, { useState } from "react";
import PropTypes from 'prop-types';
import '../../../static/styles/components/_dateFilterComponent.scss';
import {
    DatePicker,
    Form
} from "antd";

import { get, cloneDeep, isNull } from 'lodash';

import { notificationCenter } from '../../../services/appService';

import moment from "moment";

const DateFilterComponent = (props) => {

    const { filter, onFilterChange } = props;

    const [dateValue, setDateValue] = useState(isNull(get(filter, 'value._from', null)) ? null : moment(get(filter, 'value._from')));

    const onLocalChange = (value) => {
        const _from = cloneDeep(value);
        const _to = cloneDeep(value);

        if (_from.isAfter()) {
            notificationCenter('error', "Can't select dates in the future!")
        } else {
            setDateValue(moment(value));
            onFilterChange(filter, { _from: _from.format('LL'), _to: _to.format('LL') })
        }


    };
    return <Form.Item label={get(filter, 'isWeekly', false) ? "Weekly Start Date" : "Date"}>
        <DatePicker
            format="MM/DD/YYYY"
            value={dateValue}
            onChange={(event) => onLocalChange(event)}
        />
    </Form.Item>

};

DateFilterComponent.propTypes = {
    format: PropTypes.string,
    isWeekly: PropTypes.bool,
    onChangeFilter: PropTypes.func
};

export default DateFilterComponent;