import React, { useContext } from 'react';
import "../../static/styles/app.scss"
import 'react-notifications/lib/notifications.css';
import "antd/dist/antd.css";
import { SettingsContext } from '../../contexts/SettingsContext';
import { get } from 'lodash';

// Panels
import {
    HeaderSB,
    Footer,
    DownloadDocumentComponent
} from '../../components/index';

export default function LayoutSB(props) {

    const settingsContext = useContext(SettingsContext);

    return (
        <div className="app-container" >
            <div className="app-main-container">
                <div className="app-header app-header-horizontal">
                    <HeaderSB />
                </div>
                <main className="app-main-content-wrapper">

                    <div className="app-main-content">
                        {props.children}
                    </div>
                    <Footer />
                </main>
            </div>
            {get(settingsContext, 'downloadDocument.open') &&
                <DownloadDocumentComponent />
            }
        </div>
    )
};