import React, { Fragment, useContext, useState, useEffect } from "react";
import {
    getReports,
    getUserConfig,
    getLastDownloadDocuments
} from '../../services/reportServices';

import { SettingsContext } from '../../contexts/SettingsContext';
import { AuthContext } from '../../contexts/AuthContext';

import {
    CardPresentational
} from '../../components/index'

import { get } from 'lodash';


export default function Reports(props) {

    const settingsContext = useContext(SettingsContext);
    const authContext = useContext(AuthContext);

    const [reports, setReports] = useState([]);

    useEffect(() => {
        settingsContext.onChangeLoader(true);
        authContext.onSetToken(props);

        const gettingReports = async () => {
            try {
                const result = await getReports();
                setReports(JSON.parse(get(result, 'data.body')));
                settingsContext.onChangeLoader(false)
            } catch (error) {
                settingsContext.onRequestErrorMessage(error.response)
            }
        }

        const gettingUserInfo = async () => {
            try {
                const result = await getUserConfig();
                authContext.onUserInfo(get(result, 'data'));
                settingsContext.onChangeLoader(false)
            } catch (error) {
                settingsContext.onRequestErrorMessage(error.response)
            }
        };

        const gettingLastDocuments = async () => {
            try {

                const result = await getLastDownloadDocuments();
                settingsContext.onAddDocuments(get(result, 'data.documents', []), true)

            } catch (error) {
                settingsContext.onRequestErrorMessage(error.response)
            }
        }

        gettingUserInfo();
        gettingReports();
        gettingLastDocuments();
    }, []);



    const onReportSelected = (report) => {
        props.history.push(`/report/${get(report, 'key')}`, { report });
    };

    return (
        <div className='main-reports-data'>
            {reports.map(item => (
                <Fragment key={get(item, 'key')}>
                    <h1 className='mb-4' style={{ fontWeight: 'bold' }} key={get(item, 'key')}>{get(item, 'key')}</h1>
                    <div className='d-flex flex-wrap'>
                        {get(item, 'value').map(item => (
                            <CardPresentational onReportSelected={onReportSelected} key={get(item, 'key')}
                                report={item} />
                        ))}
                    </div>
                </Fragment>
            ))}
        </div>
    )
};