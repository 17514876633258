import React, { useRef } from 'react';
import { Button } from '@material-ui/core';
import { Input } from 'antd';
import { makeStyles } from "@material-ui/core/styles";
import { get } from 'lodash';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  FileCopy,
  OpenInBrowser
} from '@material-ui/icons';
import { notificationCenter } from '../../services/appService';


const useStyles = makeStyles(() => ({
  paper: {
    width: "100% !important"
  },
  button: {
    marginLeft: '4px',
    backgroundColor: '#8b8f9c',
    color: 'white',
    fontWeight: 'bold'
  },
}));
export default function DownloadNowComponent({ elem }) {

  const classes = useStyles();

  const inputLinkRef = useRef(null);

  const onCopyLink = () => {
    inputLinkRef.current.select();
    notificationCenter('success', 'Link Copied!')

  };


  const onOpenLink = () => {

    if (get(elem, 'exportType') === 'Preview') {
      window.open(get(elem, 'url'), '_blank');
    } else {
      var element = document.createElement('a');
      element.setAttribute('href', get(elem, 'url'));
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

  };


  return (
    <div className="input-share-link d-flex justify-content-between align-items-center">
      <Input ref={inputLinkRef} readOnly value={get(elem, 'url')} />
      <CopyToClipboard text={get(elem, 'url')}
        onCopy={() => onCopyLink()}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<FileCopy />}>
          Copy
        </Button>
      </CopyToClipboard>

      <Button
        variant="contained"
        color="primary"
        // disabled={get(document, 'exportType') === 'Excel'}
        className={classes.button}
        onClick={() => onOpenLink()}
        startIcon={<OpenInBrowser />}>
        Open
      </Button>

    </div>
  )
};