import React from "react";
import {
    Checkbox,
    FormControlLabel
} from '@material-ui/core';

import {get} from 'lodash';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    checkBox: {
        // paddingTop: '20px'
    }
}));

const ShowLunchBreakFilter = (props) => {

    const classes = useStyles();


    const {filter , onFilterChange} = props;


    return (
        <div>
            <FormControlLabel
                className={classes.checkBox}
                control={
                    <Checkbox
                        checked={get(filter , 'value' , false)}
                        onChange={(e) => onFilterChange(filter , e.target.checked)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="Show Lunch Break"
            />
        </div>
    )

};

export default ShowLunchBreakFilter;