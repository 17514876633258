import React, { useState } from "react";

import {
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import { get } from "lodash";

const ShowWorkSummary = props => {

  const { onFilterChange, filter } = props;

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={get(filter, 'value', false)}
            onChange={(e) => onFilterChange(filter, e.target.checked)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        }
        label="Show Work Summary"
      />
    </div>
  )
};

export default ShowWorkSummary;