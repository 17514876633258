import React, {useState} from "react";

import {get, isNull} from 'lodash';
import {TextField, Checkbox, FormControlLabel} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";



const useStyles = makeStyles((theme) => ({
    autocomplete: {
        minWidth: 200,
        maxWidth: 300,
        verticalAlign: 'baseline !important'
    },
    root: {
        verticalAlign: 'baseline !important'

    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));


const WorkerFilterComponent = (props) => {

    const {filter, onFilterChange} = props;

    const classes = useStyles();

    const [state, setState] = useState({
        value: get(filter, 'isMultiple', false) ? [get(filter, 'value', '')] : get(filter, 'value', ''),
        selectAll: false
    });

    const onLocalChange = (value) => {
        onFilterChange(filter, value);
        setState({...state, value});
    };

    const defaultProps = {
        options: get(filter , 'data' , []),
        getOptionLabel: (option) => option.name

    };


    const onAllChange = (e) => {
        const val = e.target.checked;
        const ids = get(filter, 'data').map(item => get(item, '_id'));
        setState({value: ids, selectAll: val})
    };

    return (
        <div className='d-flex justify-content-center align-items-center worker-filter'>
            <Autocomplete
                {...defaultProps}
                className={classes.autocomplete}
                style={{verticalAlign: 'baseline'}}
                id="disable-close-on-select"
                value={state.value}
                onChange={(e , value) => onLocalChange(value)}
                disabled={get(filter, 'disabled', false)}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField {...params} label="Select Worker" />
                )}
            />
            {get(filter, 'isMultiple', false) &&
            <FormControlLabel
                className={classes.checkBox}
                disabled={get(filter, 'disabled', false)}
                control={
                    <Checkbox
                        checked={state.selectAll}
                        onChange={(e) => onAllChange(e)}
                        name="checkedB"
                        color="primary"
                    />
                }
                label="All"
            />}

        </div>
    )
};

export default WorkerFilterComponent;