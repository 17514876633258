import React from 'react';
import * as _ from 'lodash';

import '../../static/styles/components/_cardPresentational.scss';

const CardPresentational = (props) => {

    const { report, onReportSelected } = props;
    return (
        <div key={_.get(report, 'key')} onClick={() => onReportSelected(report)} className="jr-card text-center card-presentational">
            <div className='jr-card-header-color'>
                <div className='report-logo'>
                    <img width='60px' height='auto' className="mb-3" src={`https://static-files.connectedbarrel.com/icons/reportApp/${_.get(report, 'key')}.svg `} alt="Integration Logo" />
                </div>
                <div className="jr-card-hd-content">
                    <span style={{ fontWeight: 'bold', fontSize: '15px' }}>{_.get(report, 'name')}</span>
                </div>
            </div>
        </div>
    )
};

export default CardPresentational;