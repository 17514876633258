import React, { useState, useEffect, useContext } from 'react'
import { get, isUndefined } from 'lodash';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';
import { emailsSearch, sendEmails } from '../../services/reportServices';
import { SettingsContext } from '../../contexts/SettingsContext';
import { Avatar, Button, Chip, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Send } from '@material-ui/icons';

import '../../static/styles/components/_sendByEmailComponent.scss';

const formatOptionLabel = ({ label, value, profile }) => {
  return (
    <ListItem button key={value}>
      <ListItemAvatar>
        <Avatar alt="Remy Sharp" src={profile} />
      </ListItemAvatar>
      <ListItemText className="br-break" primary={label} secondary={value} />
    </ListItem>
  )
};

const MultiValueContainer = ({ children, ...props }) => {

  const handleDelete = (i) => {
    props.selectProps.onChange(get(props, 'selectProps.value').filter(item => get(item, 'value') !== get(props, 'data.value')), { action: 'remove-value' });
  }

  return (
    <components.MultiValueContainer {...props}>
      <Chip
        avatar={
          <Avatar className="user-avatar size-20" src={get(props, 'data.profile')} />
        }
        label={get(props, 'data.value')}
        onDelete={handleDelete}
      />
    </components.MultiValueContainer>
  );
};
export default function SendByEmailComponent({ elem }) {

  const settingContext = useContext(SettingsContext)

  const [state, setState] = useState({
    tags: [],
    options: []
  });

  useEffect(() => {

    try {
      settingContext.onChangeLoader(true);

      const gettingOptions = async () => {
        const result = await emailsSearch()
        setState(prevState => ({
          ...prevState,
          options: get(result, 'data')
        }))

        settingContext.onChangeLoader(false);
      }
      gettingOptions();
    } catch (error) {
      settingContext.onRequestErrorMessage(error.response)
    }

  }, [])

  const handleChange = (newValue, actionMeta) => {

    setState(prevState => ({
      ...prevState,
      tags: newValue
    }))



  };

  const onSendEmail = async () => {

    try {
      settingContext.onChangeLoader(true);

      console.log(elem)
      const payload = {
        emails: state.tags.map(item => get(item, 'label')),
        documentKey: get(elem, 'documentKey'),
        documentUrl: get(elem, 'url')
      };
      await sendEmails(payload)
      settingContext.onChangeLoader(false);
      settingContext.onNotificationError('success', 'Emails sent')

    } catch (error) {
      settingContext.onRequestErrorMessage(error.response)
    }
  }

  return (
    <div className='d-flex justify-content-between align-items-center send-email-export'>
      <div style={{ width: '88%' }} className='react-tags'>
        <CreatableSelect
          isMulti
          styles={{
            menu: styles => ({ ...styles, zIndex: 99999 }),
            multiValue: styles => ({ ...styles, backgroundColor: 'none' })
          }}
          onChange={handleChange}
          formatOptionLabel={formatOptionLabel}
          options={state.options}
          components={{ MultiValueContainer }}
        />

      </div>
      <Button
        variant="contained"
        onClick={onSendEmail}
        startIcon={<Send />}>
        Send
      </Button>
    </div>

  )
};