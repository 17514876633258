import React, { useState, useContext } from 'react';
import { get, isNull, cloneDeep } from 'lodash';
import moment from "moment";
import { DatePicker } from 'antd';
import { SettingsContext } from '../../../contexts/SettingsContext';

const { RangePicker } = DatePicker;

export default function WeeklyDateRangeFilter(props) {

  const { filter, onFilterChange } = props;
  const settingsContext = useContext(SettingsContext);


  const [dates, setDates] = useState(
    isNull(get(filter, 'value._from', null)) ? null :
      [moment(get(filter, 'value._to')), moment(get(filter, 'value._from'))]
  );
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = current => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 6;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 6;
    return tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };

  const onLocalChange = (value) => {


    const _from = cloneDeep(value[0]);
    const _to = cloneDeep(value[1]);


    if (_from.isAfter() || _to.isAfter()) settingsContext.onNotificationError('error', "Can't select dates in the future!")
    else if (_to.diff(_from, 'days') !== 6) {
      settingsContext.onNotificationError('error', "You have to select all week!")
    } else {
      setValue(value)
      onFilterChange(filter, { _from: _from.format('LL'), _to: _to.format('LL') })
    }

  };

  return (
    <RangePicker
      value={hackValue || value}
      disabledDate={disabledDate}
      onCalendarChange={val => setDates(val)}
      onChange={val => onLocalChange(val)}
      onOpenChange={onOpenChange}
    />
  );
};