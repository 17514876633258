import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from './containers/App/App';
import defaultTheme from './theme';
import SettingsContextProvider from './contexts/SettingsContext';
import AuthContextProvider from './contexts/AuthContext';
import { NotificationContainer } from 'react-notifications';

const applyTheme = createMuiTheme(defaultTheme);

ReactDOM.render(
    <MuiThemeProvider theme={applyTheme}>
        <AuthContextProvider>
            <SettingsContextProvider>
                <CssBaseline />
                <App />
                <NotificationContainer />

            </SettingsContextProvider>
        </AuthContextProvider>
    </MuiThemeProvider>,
    document.querySelector('#root'),
);
