import React, { useContext, useState, useEffect } from "react";
import { get, isEmpty, find, set, size, isNull, isUndefined, indexOf, cloneDeep, has } from 'lodash';
import { CloudDownload } from '@material-ui/icons';
import moment from "moment";

import {
    getReportConfig,
    // getCompaniesByProject,
    getWorkersByCompany,
    getReportData,
    exportReportData,
    getCostcodesByProject,
    getWorkerOnboardingForm,
    getDocuments,
    populatingFiltersDependencies
} from '../../services/reportServices';

import {
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@material-ui/core";
import InfiniteScroll from 'react-infinite-scroll-component';

import { SettingsContext } from '../../contexts/SettingsContext';
import { AuthContext } from '../../contexts/AuthContext';
import { Pagination } from '../../components';

import {
    ReportFilterTop,
    TableComponent
} from '../../components/index';
import FiltersComponents from "../FiltersComponents/FiltersComponents";

const W3CWebSocket = require('websocket').w3cwebsocket;


export default function ReportData(props) {

    const settingsContext = useContext(SettingsContext);
    const authContext = useContext(AuthContext);


    const [state, setState] = useState({
        filters: [],
        page: 1,
        currentReport: null,
        canExport: true,
        pagination: null,
        showFilters: true,
        currentTimezone: null,
        documentHeaders: [],
        documents: [],
        reportData: null,
        companyWorkerFilters: null,
        exportDialog: {
            status: false,
            shareLink: ''
        }
    });

    const gettingDocuments = async () => {
        try {
            settingsContext.onChangeLoader(true);
            const report = get(props, 'location.state.report');
            const result = await getDocuments(get(report, 'key'), state.pagination);

            setState(prevState => ({
                ...prevState,
                documentHeaders: get(result, 'data.headers'),
                documents: get(result, 'data.documents'),
                pagination: get(result, 'data.lastEvaluatedKey', null)
            }))
            settingsContext.onChangeLoader(false);

        } catch (error) {

        }
    }

    const fetchMoreDocuments = async () => {

        if (state.pagination !== null) {
            const report = get(props, 'location.state.report');

            const result = await getDocuments(get(report, 'key'), state.pagination);
            setState(prevState => ({
                ...prevState,
                documentHeaders: get(result, 'data.headers'),
                documents: [...prevState.documents, ...get(result, 'data.documents')],

                pagination: get(result, 'data.lastEvaluatedKey', null)
            }))
            settingsContext.onChangeLoader(false);
        }

    }


    useEffect(() => {
        settingsContext.onChangeLoader(true);

        const report = get(props, 'location.state.report');

        const gettingReportConfig = async (report) => {
            try {
                const result = await getReportConfig(get(report, 'key'));
                console.log(JSON.parse(get(result, 'data.body')))

                setState(prevState => ({
                    ...prevState,
                    filters: JSON.parse(get(result, 'data.body')),
                    currentReport: report
                }))

            } catch (error) {
                // settingsContext.onRequestErrorMessage(error.response)
            }
        }

        gettingReportConfig(report);
        gettingDocuments();
        openWebSocketConnection();

    }, []);


    const openWebSocketConnection = () => {
        let client = new W3CWebSocket(`${get(process.env, 'REACT_APP_WSS_CONNECTION')}?userId=${get(authContext, 'userInfo.user_id')}&companyId=${get(authContext, 'userInfo.company_id')}`);

        client.onopen = (e) => {
            // console.log("OPEN ", e);
        };

        client.onclose = (e) => {
            // console.log("ONCLOSE ", e);
            client = null;

            openWebSocketConnection();
        };

        client.onmessage = (e) => {

            const res = JSON.parse(get(e, "data"));
            console.log("onMessage res", res.section)
            switch (get(res, 'section.key')) {
                case 'export':
                    set(get(res, 'section.value'), 'lastCreated', true)
                    setState(prevState => ({
                        ...prevState,
                        documents: [get(res, 'section.value'), ...prevState.documents.filter(item => get(item, 'exportAuxId') !== get(res, 'section.value.exportAuxId'))]
                    }))
                    settingsContext.onAddDocuments(get(res, 'section.value'));
                    break;
                default:
                    break;
            }
        };
    };

    const onShowFilters = () => {
        setState(prevState => ({
            ...prevState,
            showFilters: !prevState.showFilters
        }))
    };


    const onFilterDependencies = (filter, value) => {


        const filtersCopy = cloneDeep(state.filters);

        const temp = find(filtersCopy, item => indexOf(get(item, 'dependencies', []), get(filter, 'key')) > -1);

        if (isUndefined(temp)) return;

        switch (!isUndefined(temp)) {
            case get(filter, 'key') === 'projectFilter' && get(temp, 'key') === 'companyFilter':

                settingsContext.onChangeLoader(true);
                populatingFiltersDependencies({ projectId: value }, 'companiesByProject').then(res => {
                    set(temp, 'data', get(res, 'data', []));
                    set(temp, 'disabled', false);

                    setState(prevState => ({
                        ...prevState,
                        filters: filtersCopy
                    }));
                    settingsContext.onChangeLoader(false);

                }).catch(err => {
                    settingsContext.onRequestErrorMessage(err.response)
                });
                break;

            case get(filter, 'key') === 'subsCompanyFilter' && get(temp, 'key') === 'projectFilter':

                settingsContext.onChangeLoader(true);
                populatingFiltersDependencies({ companyId: value }, 'projectsByCompany').then(res => {
                    set(temp, 'data', get(res, 'data', []));
                    set(temp, 'disabled', false);

                    setState(prevState => ({
                        ...prevState,
                        filters: filtersCopy
                    }));
                    settingsContext.onChangeLoader(false);

                }).catch(err => {
                    settingsContext.onRequestErrorMessage(err.response)
                });
                break;

            case get(filter, 'key') === 'subsCompanyFilter' && get(temp, 'key') === 'workerFilter':

                settingsContext.onChangeLoader(true);
                getWorkersByCompany(value).then(res => {
                    set(temp, 'data', JSON.parse(get(res, 'data.body')));
                    set(temp, 'disabled', false);
                    setState(prevState => ({
                        ...prevState,
                        filters: filtersCopy
                    }));
                    settingsContext.onChangeLoader(false);
                }).catch(err => {
                    settingsContext.onRequestErrorMessage(err.response)

                });
                break;

            case get(filter, 'key') === 'projectFilter' && get(temp, 'key') === "costCodeFilter":

                settingsContext.onChangeLoader(true);
                getCostcodesByProject(value).then(res => {
                    set(temp, 'data', JSON.parse(get(res, 'data.body')));
                    set(temp, 'disabled', false);
                    setState(prevState => ({
                        ...prevState,
                        filters: filtersCopy
                    }));
                    settingsContext.onChangeLoader(false);

                }).catch(err => {
                    settingsContext.onRequestErrorMessage(err.response)

                });
                break;
                
            default:
                break;
        }
    };

    const onFilterChange = (filter, value) => {

        setState(prevState => ({
            ...prevState,
            filters: prevState.filters.map(item => {
                if (get(item, 'key') === get(filter, 'key')) {
                    set(item, 'value', value);
                    return item;
                }

                return item;
            })
        }));

        onFilterDependencies(filter, value);
    };


    const onExportReportData = async (exportKey) => {

        try {
            let currentFilters = cloneDeep(state.filters)

            const isValid = find(currentFilters, filter => get(filter, 'isRequired') && get(filter, 'value') === '');

            if (isUndefined(isValid)) {

                let data = {
                    exportKey,
                    filters: currentFilters.map(filter => {
                        if (has(filter, 'data')) delete filter.data
                        return filter
                    }),
                    timezone: state.currentTimezone,
                    currentReport: state.currentReport
                };

                setState(prevState => ({
                    ...prevState,
                    canExport: false
                }))

                console.log(data)

                settingsContext.onNotificationError('warning', "It may take a while, we will notify you when ready")

                const result = await exportReportData(data);

                console.log(result.data)
                setState(prevState => ({
                    ...prevState,
                    canExport: true,
                    documents: [get(result, 'data'), ...prevState.documents]
                }))

                settingsContext.onAddDocuments(get(result, 'data'));
                settingsContext.onShowDocuments();
            } else {
                settingsContext.onNotificationError('warning', "Some filters are required")
            }


        } catch (error) {
            settingsContext.onRequestErrorMessage(error.response)
        }

    };

    const onDownload = (document) => {
        settingsContext.onShowDocuments();
        settingsContext.onDownloadDocument(document, true)
    }


    return (
        !isEmpty(state.filters) &&
        <div className='report-item-content'>
            <div className='top-report'>
                <ReportFilterTop
                    canExport={state.canExport}
                    report={state.currentReport}
                    onShowFilters={onShowFilters}
                    onExportReportData={onExportReportData}
                />
                {state.showFilters &&
                    <FiltersComponents
                        filters={state.filters}
                        onFilterChange={onFilterChange}
                        onFilterDependencies={onFilterDependencies}
                    />}

                {state.documents.length > 0 ?
                    <div
                        style={{
                            height: 500,
                            overflow: 'auto'
                        }}
                    >
                        <InfiniteScroll
                            dataLength={get(state, 'documents', []).length}
                            next={fetchMoreDocuments}
                            height={500}
                            hasMore={true}
                            scrollableTarget="scrollableDiv"
                        >
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {get(state, 'documentHeaders', []).map((header, idx) => (
                                            <TableCell align={header.align} key={idx}>{header.title}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {get(state, 'documents').map((document, idx) => (
                                        <TableRow style={{ backgroundColor: `${get(document, 'lastCreated') ? '#8dbf4440' : 'white'}` }} key={idx}>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'dateCreated', '-')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'createdBy', '-')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'exportType', '-')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'dateRange._from', '-')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'dateRange._to', '-')}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {get(document, 'documentStatus', 'ready') === 'processing' ?
                                                    <CircularProgress style={{ marginLeft: '20px' }} size={20} /> : <CloudDownload onClick={() => onDownload(document)} style={{ marginLeft: '20px', color: 'rgb(141, 191, 68)', cursor: 'pointer' }} />}

                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </InfiniteScroll>
                    </div>
                    : <div style={{ textAlign: 'center', fontSize: '20px', color: "#a8a8a8" }}>
                        <i>No Data was returned!</i>
                    </div>}
            </div>

        </div>
    )
};