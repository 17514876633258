import React, { Fragment, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import '../../static/styles/components/_reportFilterTop.scss';
import * as _ from 'lodash';
import { FilterList, Send, CloudDownload } from '@material-ui/icons';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Menu, Button, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    buttonFilter: {
        margin: theme.spacing(1),
        backgroundColor: '#8b8f9c',
        color: 'white',
        fontWeight: 'bold'
    },
    buttonGenerate: {
        margin: theme.spacing(1),
        backgroundColor: '#009687',
        color: 'white',
        fontWeight: 'bold'
    },
    formControl: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        minWidth: 150,
    }
}));

const ReportFilterTop = (props) => {

    const classes = useStyles();

    const { report, canExport, onShowFilters, onExportReportData } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (item) => {

        if (_.has(item, 'key')) {
            setAnchorEl(null);
            onExportReportData(item);
        }

    };

    return (
        <Fragment>
            <Card className={`jr-card jr-card-widget report-item-header`}>
                <CardBody>
                    <div className='left-card-body'>
                        <Link to="/reports">
                            <i className="go-back-arrow mr-3 zmdi zmdi-arrow-left" />
                        </Link>


                        <img className='mr-2' width='30px'
                            src={`https://static-files.connectedbarrel.com/icons/reportApp/${_.get(report, 'key')}.svg `}
                            alt="filter img" />
                        {_.get(report, 'name')}
                    </div>
                    <div className='right-card-body d-flex flex-wrap'>
                        <Button
                            variant="contained"
                            className={classes.buttonFilter}
                            startIcon={<FilterList />}
                            onClick={() => onShowFilters()}>
                            Filters
                        </Button>

                        <div className='export-menu '>
                            <Button
                                variant="contained"
                                onClick={handleMenuClick}
                                className={classes.buttonGenerate}
                                disabled={!canExport}
                                startIcon={<Send />}>
                                Generate
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                {_.get(report, 'exports').map(item => (
                                    <MenuItem key={_.get(item, 'key')}
                                        onClick={(e) => handleMenuClose(item)}>{item.name}</MenuItem>
                                ))}
                            </Menu>
                        </div>

                        {/* <Button
                            variant="contained"
                            className={classes.buttonGenerate}
                            onClick={() => onGenerateReport()}
                            startIcon={<Send />}>
                            Generate
                        </Button> */}
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    )

};


export default ReportFilterTop;