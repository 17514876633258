import React from "react";
import PropTypes from "prop-types";
import { Card, CardTitle } from "reactstrap";

const Widget = ({ children, styleName, title, icon, logo }) => {

    return (
        <Card className={`jr-card jr-card-widget ${styleName}`}>
            {title ? <CardTitle> {logo ? <img width='200' src={logo} alt="logo" /> : null} {icon ? <i className={`fa ${icon} m`} /> : null} {title}</CardTitle> : null}
            {children}
        </Card>
    )
};

export default Widget;


Widget.defaultProps = {
    styleName: '',
};

Widget.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired
};
