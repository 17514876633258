import React from 'react';
import PropTypes from 'prop-types';

import '../../static/styles/components/_spinner.scss';

function Spinner({ text }) {
    return (
        <div className="main_spinner">
            <div className="spinner"></div>
            <span>{text}</span>
        </div>
    );
}



Spinner.propTypes = {
    text: PropTypes.string
};

Spinner.defaultProps = {
    text: "Loading..."
};

export default Spinner;