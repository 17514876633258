import React from "react";

import {Switch} from "react-router-dom";

import Route from "./Route";

import {Redirect} from "react-router-dom";

import Reports from '../pages/Reports/Reports';
import ReportData from "../pages/ReportData/ReportData";

export default function Routes(props) {

    return (
        <Switch>
            <Route path="/reports/:sessionId?" component={Reports} globalState={props}/>
            <Route path="/report/:key" component={ReportData} globalState={props}/>
            <Route path="*">
                <Redirect to="/reports"/>
            </Route>
        </Switch>
    );
}