import React, {useState} from "react";

import {
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import {get} from "lodash";

const ShiftApprovedFilterComponent = props => {

    const {onFilterChange, filter} = props;

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={get(filter, 'value', false)}
                        onChange={(e) => onFilterChange(filter, e.target.checked)}
                        color="primary"
                        inputProps={{'aria-label': 'primary checkbox'}}
                    />
                }
                label="Show approved shifts only"
            />
        </div>
    )
};

export default ShiftApprovedFilterComponent;