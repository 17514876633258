import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Slide } from '@material-ui/core';
import { get } from 'lodash';
import { SettingsContext } from '../../contexts/SettingsContext';
import {
  DownloadDocumentContent
} from '../index'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  paper: {
    width: "100% !important"
  },
  button: {
    marginLeft: '4px',
    backgroundColor: '#8b8f9c',
    color: 'white',
    fontWeight: 'bold'
  },
}));


export default function DownloadDocumentComponent(props) {

  const classes = useStyles();

  const settingsContext = useContext(SettingsContext);

  return (
    <Dialog
      open={get(settingsContext, 'downloadDocument.open')}
      className={classes.paper}
      TransitionComponent={Transition}
      disableBackdropClick={true}
      keepMounted
      maxWidth='md'
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        Download Report
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <DownloadDocumentContent elem={get(settingsContext, 'downloadDocument.document')} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => settingsContext.onDownloadDocument()}>
          Done
        </Button>
      </DialogActions>

    </Dialog>

  )
};