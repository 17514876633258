import React, { useContext } from 'react'
import { Badge, IconButton } from '@material-ui/core'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import { SettingsContext } from '../../contexts/SettingsContext';
import { get, size } from 'lodash';
import { CloudDownload } from '@material-ui/icons';
import { AppNotification } from '../index';

export default function RightHeaderPanel(props) {

  const settingsContext = useContext(SettingsContext);

  return (
    <ul className="header-notifications list-inline ml-auto">

      <li style={{ cursor: "pointer" }} className="list-inline-item app-tour">
        <Dropdown
          className="quick-menu"
          style={{ cursor: 'auto' }}
          isOpen={get(settingsContext, 'export.open') && size(get(settingsContext, 'export.documents')) > 0}
          toggle={settingsContext.onShowDocuments}>

          <DropdownToggle
            className="d-inline-block"
            tag="span"
            data-toggle="dropdown">
            <IconButton className="icon-btn">
              <Badge badgeContent={size(get(settingsContext, 'export.documents', []))} color="secondary">
                <CloudDownload />
              </Badge>
            </IconButton>
          </DropdownToggle>

          <DropdownMenu right>
            <AppNotification />
          </DropdownMenu>
        </Dropdown>
      </li>

    </ul>

  )
};