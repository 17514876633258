import React, { useState, createContext } from 'react'
import { get, isNull, isUndefined } from 'lodash';

export const AuthContext = createContext();

export default function AuthContextProvider(props) {

  const [state, setState] = useState({
    userInfo: JSON.parse(localStorage.getItem('userInfo')),
    isLogged: !isNull(localStorage.getItem('userToken')),
    userToken: null
  });

  const onSetToken = data => {

    const token = get(data, 'match.params.sessionId', null);

    if (isNull(state.userToken) && !isNull(token)) {
      data.history.push('/reports');
      localStorage.setItem('userToken', token)
      setState(prevState => ({
        ...prevState,
        isLogged: true,
        userToken: localStorage.getItem('userToken')
      }))
    }

  }

  const onUserInfo = data => {

    let info = {
      user_id: get(data, 'user_id'),
      company_id: get(data, 'company_id'),
      user_type: get(data, 'user_type'),
      first_name: get(data, 'first_name', 'Israel'),
      last_name: get(data, 'last_name', 'Bernal'),
      avatar: get(data, 'avatar', 'defaultImage Go Here!!!!')
    };

    localStorage.setItem('userInfo', JSON.stringify(info))

    setState(prevState => ({
      ...prevState,
      userInfo: info
    }))
  }

  const onLogout = () => {
    localStorage.clear()
    setState(prevState => ({
      ...prevState,
      userInfo: {},
      isLogged: false,
      userToken: null
    }))
  }

  return (
    <AuthContext.Provider value={{
      ...state,
      onSetToken,
      onLogout,
      onUserInfo
    }}>
      {props.children}
    </AuthContext.Provider>
  )
};