import React from 'react';
import {
    AppBar,
    Toolbar,
    makeStyles
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import { get } from 'lodash';
import { RightHeaderPanel } from '../index';

const useStyles = makeStyles(theme => ({
    menuButton: {
        paddingBottom: "8px",
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    }
}));


const HeaderSB = (props) => {

    return (
        <AppBar className="app-main-header" position="static">
            <Toolbar className="app-toolbar d-flex justify-content-between align-items-center" disableGutters={false}>
                <div className='left-header-panel d-flex align-items-center'>
                    <a className="mr-3 d-sm-block" href={get(process.env, 'REACT_APP_BASE_DASHBOARD_URL')}>
                        <i className="zmdi zmdi-home icon-home-dashboard" />
                    </a>
                    <Link className="app-logo mr-2 d-sm-block" to="/reports">
                        <img src={props.logoPath} width="100" height="auto" alt="SmartBarrel" title="SmartBarrel" />
                    </Link>
                </div>

                <RightHeaderPanel />
            </Toolbar>

        </AppBar>
    )
};

HeaderSB.defaultProps = {
    logoPath: "https://s3-us-west-2.amazonaws.com/static-files.connectedbarrel.com/images/logo/SmartBarrel_White.png"
};

export default HeaderSB;